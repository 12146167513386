@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap');
/* Show it is fixed to the top */
body {
  min-height: 75rem;
  font-family: 'Readex Pro';
}
.align-center {
  margin: 100px auto;
}
h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 140.62%;
  color: #2a3741;
}

p {
  font-family: 'Readex Pro';
  font-style: normal;
  font-size: 16px;
  line-height: 140.62%;
  /* or 22px */
  color: #69737b;
}
a,
a:hover {
  color: #67ac41;
}
.container-2 {
  max-width: 1092px;
}
hr.divider-border {
  height: 28px;
  width: 1px;
  display: inline-block;
  margin: 0 21px;
  position: relative;
  top: 11px;
}
.btn-white {
  background: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140.62%;

  border-radius: 10px;
  padding: 10px 20px;
  color: #67ac41;
}
.btn-green {
  background: #67ac41;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140.62%;

  border-radius: 10px;
  padding: 10px 20px;
  color: #fff !important;
}
.btn-green.nav-link {
  margin-left: 20px;
}
nav#nav.sticky .btn-green.nav-link {
  color: #fff !important;
}
.btn-outline-green {
  background: #fff;
  font-style: normal;
  font-weight: 700;
  line-height: 140.62%;

  border-radius: 10px;
  padding: 10px 20px;
  color: #67ac41 !important;
  border: 1px solid #67ac41;
}
.btn-login {
  font-size: 14px;
}
.title-download {
  font-size: 16px !important;
}
a.nav-link {
  font-size: 13px;
  padding: 10px 20px !important;
  color: #fff !important;
  opacity: 1;
}

ul.navbar-nav {
  justify-content: end;
  display: flex;
  flex: 0 0 100%;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
nav {
  transition: 0.5s all;
}
nav#nav.sticky {
  background: #fff;
  box-shadow: 0 0 20px #33333338;
}

nav#nav.sticky .nav-link {
  color: #333 !important;
}

.dark-logo {
  display: none;
}

.sticky img {
  display: none;
}
.sticky .dark-logo {
  display: block;
  height: 44px;
}
#banner {
  background: url(../img/bg-banner.png) no-repeat;
  min-height: 100vh;
  background-size: 100%;
  padding-top: 50px;
  background-color: #4690d3;
  background-position: bottom;
}

#banner .col-md-5 {
  position: relative;
  height: 85vh;
}
#banner h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 130%;
  color: #ffffff;
  display: flex;
  align-items: center;
  height: 60vh;
}
#banner img {
  height: auto;
  bottom: -5vh;
  right: 0vh;
  position: absolute;
  width: 110%;
}
#tentang-kami {
  padding: 50px 0;
}

#tentang-kami h2 {
  margin-bottom: 30px;
}

#tentang-kami h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140.62%;
  /* or 28px */

  display: flex;
  align-items: center;

  color: #1f5400;
}
#tentang-kami hr {
  width: 1px;
  height: 80%;
  margin-top: 0;
}
.counter-box h3 {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140.62%;
  /* identical to box height, or 34px */

  display: flex;
  align-items: center;

  color: #2a3741;
}
.counter-box h2 {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 49px;
  line-height: 140.62%;
  /* or 84px */
  display: flex;
  align-items: center;
  color: #fb6009;
  margin-top: 30px;
}
.counter-box {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-top: 21px;
  margin-top: 50px;
}
#layanan-kami {
  background: url(../img/bg-globe.png) no-repeat;
  background-position: center 80px;
  background-size: 90%;
  padding-bottom: 100px;
}
.box-layanan {
  background: #ffffff;
  box-shadow: 0px 25px 45px rgb(167 167 167 / 44%);
  border-radius: 5px;
  width: 65%;
  padding: 10px;
  margin: 25px;
}

.box-layanan span {
  display: inline-block;
  background: #67ac41;
  box-shadow: 0px 25px 45px rgba(167, 167, 167, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-right: 20px;
}

#benefit-kami {
  background: url(../img/bg-benefit.png) no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 40vh 0 0;
  margin: -40px 0 50px;
  color: #fff;
  min-height: 100vh;
}

#benefit-kami h2 {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 140.62%;
  /* or 56px */
  color: #ffffff;
}

#benefit-kami p {
  font-weight: 400;
  font-size: 14px;
  line-height: 140.62%;
  /* or 20px */
  color: #ffffff;
  margin-top: 31px;
}

#benefit-kami ul {
  list-style: none;
  padding: 0;
}

#benefit-kami ul li {
  padding: 20px;
  border-bottom: 1px solid #cad4dd;
  display: flex;
}

#benefit-kami ul li:last-child {
  border: none;
}

#benefit-kami ul li img {
  margin-right: 20px;
  align-self: center;
}

#aplikasi-kami img {
  max-width: 100%;
  align-self: center;
}
.app-kami {
  margin-bottom: 70px;
}
.app-kami h2 {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  /* or 43px */

  display: flex;
  align-items: center;

  color: #282c30;
}

.app-kami h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140.62%;
  /* or 25px */

  display: flex;
  align-items: center;

  color: #2a3741;
}

.app-kami p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140.62%;
  /* or 20px */
  color: #69737b;
}
.app-kami .col-md-6.d-flex.justify-content-start {
  padding: 30px;
}

#kontak-kami {
  background: url(../img/bg-globe2.png) no-repeat #6ab43e;
  color: #fff;
  margin-top: 50px;
}
#kontak-kami h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 140.62%;
  /* or 56px */
  margin-top: 50px;
  color: #ffffff;
}

#kontak-kami p {
  font-weight: 400;
  font-size: 16px;
  line-height: 140.62%;
  /* or 22px */

  color: #ffffff;
}

#kontak-kami img {
  margin-top: -60px;
}
footer {
  background: #1f5400;
  color: #fff;

  padding: 50px 0;
}

footer h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: 140.62%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  color: #6ab43e;
}
footer ul {
  list-style: none;
  padding: 0;
}

footer p,
footer li {
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  /* or 25px */

  display: flex;
  align-items: center;

  color: #ffffff;
}
footer li {
  background: url(../img/bullet-ic.png) no-repeat;
  background-position: left center;
  padding-left: 20px;
}
.terms-section {
  margin: 150px 0 50px;
}
@media (max-width: 768px) {
  #banner h2 {
    font-size: 26px;
    height: auto;
    margin-top: 50px;
  }

  #banner img {
    width: 70%;
    position: relative;
    top: 0;
    right: -10vh;
    height: auto;
  }

  #banner .col-md-5 {
    text-align: right;
    height: auto;
  }
  .sticky .navbar-dark .navbar-toggler {
    color: #333;
    border-color: #333;
  }

  #tentang-kami hr {
    width: 100%;
    height: 1px;
  }

  .counter-box {
    max-width: 100%;
    margin: 30px auto;
  }

  .container,
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .box-layanan {
    margin: 25px 5px 0;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
  }

  h2,
  #benefit-kami h2,
  .app-kami h2,
  #kontak-kami h2 {
    font-size: 24px;
  }

  #tentang-kami h3 {
    font-size: 18px;
  }

  #tentang-kami p,
  p,
  #benefit-kami ul li,
  #kontak-kami p {
    font-size: 14px;
  }

  #aplikasi-kami img.me-3 {
    max-width: none;
    width: 65px;
  }

  #kontak-kami {
    padding: 21px 0;
    text-align: center;
  }
  .sticky .navbar-toggler img {
    display: block;
  }

  .sticky .navbar-toggler {
    padding: 0;
  }
  .navbar-collapse {
    position: absolute;
    right: 0;
    background: #fff;
    top: 61px;
    min-height: 100vh;
    box-shadow: 0 12px 12px #3333335c;
    width: 200px;
    padding-right: 20px;
  }

  .navbar-collapse .nav-link.btn-green {
    text-align: center !important;
  }
  .navbar-collapse .nav-link {
    color: #333 !important;
  }
  .navbar-toggler:focus,
  .navbar-toggler:hover {
    outline: none !important;
    border: none !important;
  }
  .fixed-top {
    background: #fff;
    padding: 5px 0 !important;
  }

  .fixed-top .navbar-brand img {
    display: none;
  }

  .fixed-top .navbar-brand img.dark-logo {
    display: block;
    height: 43px;
  }
  footer p,
  footer li {
    font-size: 12px;
  }

  .btn-white {
    font-size: 14px;
  }

  #kontak-kami img {
    width: 73%;
  }

  #kontak-kami h2 {
    margin-top: 10px;
  }
  .app-kami .d-flex .col-md-6 {
    width: 50%;
  }

  .app-kami .d-flex .col-md-6 p {
    font-size: 13px;
  }

  .app-kami .d-flex .col-md-12 {
    text-align: center;
  }
  #banner {
    min-height: auto;
  }

  .app-kami h4 {
    font-size: 16px;
  }

  .app-kami h2 {
    font-size: 20px;
  }
  #tentang-kami h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1360px) {
  .navbar .container-fluid {
    max-width: 1920px;
  }

  #banner .col-md-5 img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 1780px) {
  #banner .col-md-5 img {
    width: 120%;
    height: auto;
    right: -10vh;
  }
  section#banner {
    min-height: 90vh;
  }

  section#banner .col-md-5 {
    height: 75vh;
    right: -10vh;
  }
}

/*----style popup----*/

.popup-bantuan .row {
  height: 100%;
}

.popup-bantuan .modal-dialog {
  margin: 100px auto;
}

.popup-bantuan .img-side {
  padding: 30px;
  border-radius: 10px 0px 0px 10px;
  background: url(../img/bg-globe2.png) no-repeat #6ab43e;
  background-size: cover;
}

.popup-bantuan .img-bantuan {
  margin: 40px 0 30px;
}

.popup-bantuan .modal-content {
  border: none;
  border-radius: 10px;
}

.img-bantuan img {
  width: 100%;
  height: auto;
}

.popup-bantuan .teks-side {
  margin: 0px;
  padding: 10px 30px 10px 20px;
}

.teks-image h3,
.teks-image p {
  color: #fff;
}

.teks-kontak p {
  color: #000;
  font-size: 12px;
  margin-bottom: 30px;
}

.teks-side .kontak-list {
  margin-bottom: 20px;
}

.teks-side .teks-kontak {
  margin: 60px auto;
}

.kontak-list .row {
  display: inline-flex;
  width: 100%;
}

.kontak-list button {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px 0px;
  background-color: #fff;
  width: 100%;
}

.img-phone img {
  margin: 15px auto;
  width: 30px;
}

.kontak-list .no-helpdesk {
  padding: 0px;
  text-align: left;
  margin: 5px 0px;
}

.no-helpdesk p {
  margin-bottom: 5px;
  color: #9d9d9d;
}
